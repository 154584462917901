import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./Tombstone.module.css";
import tombstone from "../../images/tombstone.png";
import aboutLink from "../../images/links/about.png";
import galleryLink from "../../images/links/gallery.png";
import communityLink from "../../images/links/community.png";
import teamLink from "../../images/links/team.png";
import animatedBackground from "../../animations/main_bg.json";
import zombie from "../../animations/arm_anim.json";

import dead1 from "../../images/dead/iDeg01.png";
import dead2 from "../../images/dead/iDeg02.png";
import dead4 from "../../images/dead/iDeg04.png";
import dead5 from "../../images/dead/iDeg05.png";
import dead6 from "../../images/dead/iDeg06.png";
import dead7 from "../../images/dead/iDeg07.png";
import dead8 from "../../images/dead/iDeg08.png";
import dead9 from "../../images/dead/iDeg09.png";
import dead10 from "../../images/dead/iDeg10.png";
import dead11 from "../../images/dead/iDeg11.png";
import dead12 from "../../images/dead/iDeg12.png";
import dead13 from "../../images/dead/iDeg13.png";

//helpers
import { useWindowDimensions } from "../../helpers/useWindowDimensions";
import { randomImage } from "../../helpers/randomImage";
import { handleScroll } from "../../helpers/handleScroll";

//libraries
import { Player } from "@lottiefiles/react-lottie-player";

const Tombstone = ({ refs }) => {
  const imgSrc = useMemo(() => {
    return [
      dead1,
      dead2,
      dead4,
      dead5,
      dead6,
      dead7,
      dead8,
      dead9,
      dead10,
      dead11,
      dead12,
      dead13,
    ];
  }, []);

  const { isPC } = useWindowDimensions();
  const { mainRef, aboutRef, teamRef, communityRef, galleryRef } = refs || {};
  const zombieAnimation = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false);
  const [image, setImage] = useState(imgSrc[0]);
  const [handleTimeout, sethandleTimeout] = useState(false);

  const handleFirstPlayer = (e) => {
    if (e === "load") {
      setIsAnimationLoaded(true);
      zombieAnimation.current?.setLoop(true);
      zombieAnimation.current?.play();
    }

    if (e === "loop") {
      sethandleTimeout(true);
    }
  };

  useEffect(() => {
    if (handleTimeout && isAnimationLoaded && isLoaded) {
      const timeout = setTimeout(() => {
        setImage(randomImage(imgSrc, image));
        sethandleTimeout(false);
      }, 600);
      return () => clearTimeout(timeout);
    }
  }, [handleTimeout, image, imgSrc, isAnimationLoaded, isLoaded]);

  return (
    <div
      className={[styles.wrapper, !isPC ? styles.wrapperResponsive : ""].join(
        " "
      )}
    >
      <Player
        src={animatedBackground}
        loop
        autoplay
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
      <div className={styles.tombstoneWrapper}>
        <div className={styles.tombstone} ref={mainRef}>
          <div
            className={styles.aboutLinkMarker}
            onClick={() => handleScroll(aboutRef)}
          />
          <div
            className={styles.galleryLinkMarker}
            onClick={() => handleScroll(galleryRef)}
          />
          <div
            className={styles.teamLinkMarker}
            onClick={() => handleScroll(teamRef)}
          />
          <div
            className={styles.communityLinkMarker}
            onClick={() => handleScroll(communityRef)}
          />
          <img
            className={[
              styles.tombstoneImage,
              !isPC ? styles.tombstoneImageResponsive : "",
            ].join(" ")}
            src={tombstone}
            alt="Tombstone"
            onLoad={() => setIsLoaded(true)}
          />
          <img
            src={image}
            alt={"igni zombie"}
            className={styles.deadShowcase}
          />
          <img src={aboutLink} alt="about link" className={styles.aboutLink} />
          <img
            src={galleryLink}
            alt="gallery link"
            className={styles.galleryLink}
          />
          <img src={teamLink} alt="team link" className={styles.teamLink} />
          <img
            src={communityLink}
            alt="community link"
            className={styles.communityLink}
          />
        </div>
        <div className={styles.zombieHand}>
          <Player
            ref={zombieAnimation}
            src={zombie}
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
            onEvent={handleFirstPlayer}
          />
        </div>
      </div>
    </div>
  );
};

export default Tombstone;
