export const randomImage = (images, image) => {
  const currentIndex = Math.floor(Math.random() * images.length);
  let imageToReturn = images[currentIndex];

  if (image === imageToReturn) {
    if (currentIndex > 0 && currentIndex <= images.length - 1) {
      return images[currentIndex - 1];
    } else {
      return images[currentIndex + 1];
    }
  } else {
    return imageToReturn;
  }
};
