//images
import React, { forwardRef, useMemo } from "react";
import about from "../../images/about.png";
import styles from "./ScrollSection.module.css";
import collection from "../../images/collection.png";
import specs from "../../images/specs.png";
import traitsFirst from "../../images/traits1.png";
import traitsSecond from "../../images/traits2.png";
import team from "../../images/team.png";
import igni from "../../images/igni.png";
import chainhive from "../../images/chainhive.png";
import gallery from "../../images/gallery.png";
import dead from "../../images/dead.png";
import community from "../../images/community.png";
import discord from "../../images/links/discord.png";
import telegram from "../../images/links/telegram.png";
// import youtube from "../../images/links/youtube.png";
import instagram from "../../images/links/instagram.png";
import twitter from "../../images/links/twitter.png";

//components
import AsideNav from "../AsideNav/AsideNav";

//hooks
import { useWindowDimensions } from "../../helpers/useWindowDimensions";

const ScrollSection = forwardRef((props, ref) => {
  const { isPC, isMobile } = useWindowDimensions();

  const { aboutRef, teamRef, communityRef, galleryRef } = ref || {};

  const handleText = useMemo(() => {
    if (isPC) {
      return (
        <>
          <p>
            The zombie apocalypse has taken a drastic turn... for the <br />
            stylish! <br />
            In the year 14 A.S., a group of necromancers had gathered <br /> at
            a graveyard to summon a storm of all storms to disrupt <br /> the
            metaverse, but one of them tweaked a cyberspell to <br />
            his own liking, and when the lightning struck, it altered <br /> the
            zombie virus. <br />
            <br />
            Enter IgniDEAD! <br />
            <br />
            New breed of zombies awoke. No longer are they <br />
            brainless and drooling. This new wave of zombies not <br />
            only have great expressions and personalities, but they <br /> also
            retain their stylish characteristics.
            <br /> The IgniDEAD are rampant and anxious to spread their <br />{" "}
            fresh styles and personalities far from their tombstones,
            <br /> to every corner of the planet.
            <br />
            <br /> Which ID you got?
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            The zombie apocalypse has taken a drastic turn... for the stylish!
            In the year 14 A.S., a group of necromancers had gathered at a
            graveyard to summon a storm of all storms to disrupt the metaverse,
            but one of them tweaked a cyberspell to his own liking, and when the
            lightning struck, it altered the zombie virus. Enter IgniDEAD! New
            breed of zombies awoke. No longer are they brainless and drooling.
            This new wave of zombies not only have great expressions and
            personalities, but they also retain their stylish characteristics.
            The IgniDEAD are rampant and anxious to spread their fresh styles
            and personalities far from their tombstones, to every corner of the
            planet. Which ID you got?
          </p>
        </>
      );
    }
  }, [isPC]);

  return (
    <>
      <div
        className={[
          styles.mainContainer,
          !isPC ? styles.responsiveContainer : "",
        ].join(" ")}
      >
        <div
          className={[
            styles.contentContainer,
            !isPC ? styles.contentContainerResponsive : "",
          ].join(" ")}
        >
          <img
            src={about}
            alt="about text"
            className={styles.title}
            ref={aboutRef}
          />
          <div
            className={[
              styles.rowWrapper,
              !isPC ? styles.columnWrapper : "",
            ].join(" ")}
          >
            <div
              className={[
                styles.rowElement,
                !isPC ? styles.rowElementResponsive : "",
              ].join(" ")}
            >
              {handleText}
            </div>
            <img
              src={collection}
              alt="nft images"
              className={[
                styles.nftCollection,
                isMobile ? styles.nftCollectionResponsive : "",
              ].join(" ")}
            />
          </div>
        </div>

        <div
          className={[
            styles.contentContainer,
            !isPC ? styles.contentContainerResponsive : "",
          ].join(" ")}
        >
          <img src={specs} alt="specs text" className={styles.title} />
          <div
            className={[
              styles.textWrapper,
              !isPC ? styles.textWrapperResponsive : "",
            ].join(" ")}
          >
            <p>
              The collection of 10,000 NFTs will have a truly unique take on a
              saturated market, by having an original setup of building facial
              structures for each zombie, spawning them with completely
              different faces, and differentiating them from the next, before
              even any stylish accessories have been applied. But to add even
              more uniqueness, the engine building the zombies will have an
              added, yet complicated task of assigning one of five skin variants
              to add to the richness of the collection. And for the true
              collectors, there will be 69 Legendary One-offs with gold
              backgrounds that have been individually hand produced, that just
              might resemble someone you might know.
            </p>
          </div>
          <div
            className={[
              styles.traitsWrapper,
              !isPC ? styles.traitsWrapperResponsive : "",
            ].join(" ")}
          >
            <img src={traitsFirst} alt="traits" />
            <img src={traitsSecond} alt="traits" />
          </div>
        </div>

        <div
          className={[
            styles.contentContainer,
            !isPC ? styles.contentContainerResponsive : "",
          ].join(" ")}
        >
          <img
            src={team}
            alt="team text"
            className={styles.title}
            ref={teamRef}
          />
          <div
            className={[
              styles.textWrapper,
              !isPC ? styles.textWrapperResponsive : "",
            ].join(" ")}
          >
            <p>
              Team of necromancers and cryptomages that stirred up the spells
              that resurrected the IgniDEAD:
            </p>
          </div>
          <div
            className={[
              styles.teamWrapper,
              !isPC ? styles.teamWrapperResponsive : "",
            ].join(" ")}
          >
            <div
              className={[
                styles.column,
                !isPC ? styles.columnResponsive : "",
              ].join(" ")}
            >
              <img src={igni} alt="igni art" />
              <p>
                Master chief artist, Ogi Grujic, the creator of the concept,
                mastermind of the project, and the owner of the posessed right
                hand that produced all the scattered art pieces of the
                collection.
              </p>
              <a
                className={styles.domain}
                href={"https://igneousart.com/"}
                target="blank"
              >
                {" "}
                www.IgneousArt.com{" "}
              </a>
            </div>
            <div
              className={[
                styles.column,
                !isPC ? styles.columnResponsive : "",
              ].join(" ")}
            >
              <img src={chainhive} alt="chainhive" />
              <p>
                Smart contract collective with the digital scrolls and metaverse
                development services, responsible for gluing together the dead
                traits into single undead forms, binding them to smart contract
                NFTs, and setting up the website for minting, releasing the
                undead horde worldwide.
              </p>
              <a
                className={styles.domain}
                href={"https://chainhive.io/"}
                target="blank"
              >
                www.chainhive.io
              </a>
            </div>
          </div>
        </div>

        <div
          className={[
            styles.contentContainer,
            !isPC ? styles.contentContainerResponsive : "",
          ].join(" ")}
        >
          <img
            src={gallery}
            alt="gallery text"
            className={styles.title}
            ref={galleryRef}
          />
          <div
            className={[
              styles.textWrapperLeft,
              !isPC ? styles.textWrapperLeftResponsive : "",
            ].join(" ")}
          >
            <p>
              The gallery will unearth after the minting has been completed.
            </p>
          </div>
          <img
            src={dead}
            alt="dead collection"
            className={styles.deadCollection}
          />
        </div>

        <div
          className={[
            styles.contentContainer,
            !isPC ? styles.contentContainerResponsive : "",
          ].join(" ")}
        >
          <img
            src={community}
            alt="community text"
            className={styles.title}
            ref={communityRef}
          />
          <div className={styles.communityWrapper}>
            <a href={"https://discord.gg/aZTBGdUN"} target="blank">
              <img src={discord} alt="discord" />
            </a>
            <a href={"https://twitter.com/ignidead/"} target="blank">
              <img src={twitter} alt="twitter" />
            </a>
            <a href={"https://www.instagram.com/ignidead/"} target="blank">
              <img src={instagram} alt="instagram" />
            </a>
            <a href={"https://t.me/ignidead"} target="blank">
              <img src={telegram} alt="telegram" />
            </a>
          </div>
        </div>
        <div
          className={[styles.date, !isPC ? styles.dateResponsive : ""].join(
            " "
          )}
        >
          <p>Coming Summer ‘23</p>
        </div>
      </div>
      {isPC ? <AsideNav sectionRefs={ref} /> : null}
    </>
  );
});

export default ScrollSection;
