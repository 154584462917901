import "./App.css";
import IgniDead from "./Components/Main/IgniDead";

function App() {
  return (
    <div className="App">
      <IgniDead />
    </div>
  );
}

export default App;
