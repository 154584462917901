import { useState, useEffect } from "react";

const tabletWidth = 1024;
const mobileWidth = 430;

export const useWindowDimensions = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isPC, setIsPC] = useState(true);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width <= mobileWidth) {
        setIsMobile(true);
        setIsPC(false);
        setIsTablet(false);
      } else if (width <= tabletWidth) {
        setIsTablet(true);
        setIsPC(false);
        setIsMobile(false);
      } else {
        setIsPC(true);
        setIsTablet(false);
        setIsMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isPC]);

  return { isPC, isTablet, isMobile };
};
