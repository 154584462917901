import React, { useMemo, useRef } from "react";
import styles from "./IgniDead.module.css";
import Tombstone from "../Tombstone/Tombstone";
import ScrollSection from "../ScrollSection/ScrollSection";

const IgniDead = () => {
  const mainRef = useRef();
  const aboutRef = useRef();
  const teamRef = useRef();
  const communityRef = useRef();
  const galleryRef = useRef();

  const sectionRefs = useMemo(() => {
    return {
      mainRef,
      aboutRef,
      teamRef,
      communityRef,
      galleryRef,
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Tombstone refs={sectionRefs} />
      <ScrollSection ref={sectionRefs} />
    </div>
  );
};

export default IgniDead;
