import React from "react";
import styles from "./AsideNav.module.css";
import { handleScroll } from "../../helpers/handleScroll";
import { useIntersectionObserver } from "../../helpers/useIntersectionObserver";

const AsideNav = ({ sectionRefs }) => {
  const { mainRef, aboutRef, teamRef, communityRef, galleryRef } =
    sectionRefs || {};

  const main = useIntersectionObserver(mainRef);
  const about = useIntersectionObserver(aboutRef);
  const team = useIntersectionObserver(teamRef);
  const community = useIntersectionObserver(communityRef);
  const gallery = useIntersectionObserver(galleryRef);

  return (
    <div className={styles.container}>
      <div
        className={[styles.section, main ? styles.visited : ""].join(" ")}
        onClick={() => handleScroll(mainRef)}
      />
      <div
        className={[styles.section, about ? styles.visited : ""].join(" ")}
        onClick={() => handleScroll(aboutRef)}
      />
      <div
        className={[styles.section, team ? styles.visited : ""].join(" ")}
        onClick={() => handleScroll(teamRef)}
      />
      <div
        className={[styles.section, gallery ? styles.visited : ""].join(" ")}
        onClick={() => handleScroll(galleryRef)}
      />
      <div
        className={[styles.section, community ? styles.visited : ""].join(" ")}
        onClick={() => handleScroll(communityRef)}
      />
    </div>
  );
};

export default AsideNav;
